import { useRef, useState } from 'react'
import { Transition } from '@headlessui/react'
import { TypographyParagraph } from '../typography'

const Tooltip = ({
	title,
	tip,
	content,
	children,
	width = 'max-w-xs',
}: {
	title?: string
	tip?: string
	content: any
	children: any
	width?: string
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const buttonRef = useRef(null)

	const openTooltip = () => {
		setIsOpen(true)
	}

	const closeTooltip = () => {
		setIsOpen(false)
	}

	return (
		<div className="relative">
			<button
				type="button"
				ref={buttonRef}
				onMouseEnter={openTooltip}
				onMouseLeave={closeTooltip}
				className="h-full w-full cursor-pointer"
			>
				{children}
			</button>

			<Transition
				as="div"
				show={isOpen}
				enter="transition-opacity duration-150"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-150"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				{() => (
					<div
						className={`absolute flex w-full flex-col items-center ${
							tip == 'Bottom' ? 'bottom-full translate-y-[-0.5rem]' : 'mt-2'
						}`}
					>
						{tip == 'Top' && (
							<div className="absolute -mt-2 h-4 w-4 rotate-45 transform bg-grey800 dark:bg-white"></div>
						)}
						<div
							className={`z-10 ${width} rounded-lg bg-grey800 p-2 dark:bg-white`}
						>
							<TypographyParagraph
								size={'medium'}
								additionalClasses="text-center text-textPrimaryDark mb-1 dark:text-textPrimaryLight"
							>
								{title}
							</TypographyParagraph>
							<TypographyParagraph
								size={'small'}
								additionalClasses="text-center text-textPrimaryDark dark:text-textPrimaryLight"
							>
								{content}
							</TypographyParagraph>
						</div>
						{tip == 'Bottom' && (
							<div className="-mt-2 h-4 w-4 rotate-45 transform bg-grey800 dark:bg-white"></div>
						)}
					</div>
				)}
			</Transition>
		</div>
	)
}

export default Tooltip
